.carousel1 {
    position: relative;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li{
            float: left;
        }
    }
    .arrows {
        @include vertical-center;
        width: 100%;
        a{
            color: #392f33;
            height: 30px;
            width: 30px;
            top: 50%;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            background-color: rgba(255,255,255,.74);
            font-size:rem-calc(30);
            line-height:rem-calc(30);
        }
        .next-btn {
            right: 10px;
            position: absolute;
        }
        .prev-btn {
            left: 10px;
            position: absolute;
        }
    }
}
.carousel2 {
    position: relative;
    ul {
        margin: 0px -10px;
        padding: 0;
        list-style-type: none;
        li{
            float: left;
            width: 390px;
            margin-right: 20px;
            padding: 0px 10px;
        }
    }
    .arrows {
        @include vertical-center;
        width: 100%;
        a{
            color: #392f33;
            height: 30px;
            width: 30px;
            top: 50%;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            background-color: rgba(255,255,255,.74);
            font-size:rem-calc(30);
            line-height:rem-calc(30);
        }
        .next-btn {
            right: 10px;
            position: absolute;
        }
        .prev-btn {
            left: 10px;
            position: absolute;
        }
    }
}

