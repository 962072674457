.prevention {
    #content {
        background-image: none;
    }
    .description {
        margin-bottom: 100px;
        .box1 {
            img {
                object-fit: cover;
                object-position: center center;
                width: 100%;
                max-height: 460px;
            }
            .inner-content{
                padding: 0px 25px;
            }
        }
    }
}