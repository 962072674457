/*
*pagination
*/
  .pagination {
    border-radius: 0;
    display: block;
    text-align: center;
    margin-bottom: 25px;
  .page-item {
    display: inline-block;
    margin: 0 5px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: rem-calc(14);
    @include cubic-transition;
    .page-link {
      color: inherit;
      border: 0;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      background: rgba(0,0,0,0);
      @include cubic-transition;
    }
    &:first-child , &:last-child {
      .page-link {
        border-radius: 50%;
      }
    }
    &.active , &:hover {
      .page-link {
        width: 33px;
        height: 33px;
        background: #d2eef9;
        border-radius: 50%;
        color: #09a3e0;
        @include cubic-transition;
      }
    &:first-child , &:last-child {
      .page-link {
        border-radius: 50%;
      }
    }
    }
   }
  }
