#header .logo {
  float:left;
  position: relative;
    &:after {
      content: "";
      background-image: url("../images/logo-1.png");
      height: 132px;
      width: 84px;
      display: block;
      position: absolute;
      top: 0;
      right: -84px;
      z-index: 1;
    }
}

@media screen and (max-width: 991px) {
#header .logo{
     max-width: 147px;
     img{
    width: 100%;
   }
   &:after{
  height: 72px;
  width: 45px;
  top: 0;
  right: -45px;
  background-size: cover;

   }
   }

}
