.phonebook-search {
    padding-left: 30px;
    padding-right: 30px;
    input[type="text"] {
        background-color: #ffffff;
        color: rgba(155, 155, 155, .7);
        border: none;
        border-radius: 5px;
        padding: 10px 0px 10px 15px;
        width: 26%;
        margin-right: 15px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .selectbox{ position: relative;}
    .selectbox::before {
     background: url("../images/select.png") no-repeat center center #ffffff; 
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    right: 30px;
    top: 50%;
    z-index: 1;
    pointer-events: none;
    margin-top: -4px;
}
    select {
        color: rgba(155, 155, 155, .7);
        width: 27%;
        margin-right: 10px;
        background-color: #ffffff;
        border: none;
        padding: 10px 0px;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 700;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance:none; 
        padding-left: 15px;
    }
    input[type="submit"] {
        border-radius: 5px;
        background: #09a3e0;
        border: none;
        color: #fff;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 120px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
font-weight: 700;    
    @include cubic-transition;
    &:hover{
      background: #2fc4ff;
      @include cubic-transition;
    }    
    }
}
@media screen and (max-width:992px){
    .phonebook-search {
        max-width: 487px;
display: block;
margin: 0 auto;
        input[type="submit"] , select , input[type="text"] { width: 48%; margin-right: 5px; margin-bottom: 20px;}
    } 
}
@media screen and (max-width: 767px) {
    .phonebook-search {
        max-width: 487px;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        margin: 0 auto;
        input[type="submit"], select, input[type="text"] {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 20px;
        }
        .selectbox {
            display: block;
            &:before {
                margin-top: -10px;
                right: 15px;
            }
        }
    }
}

.phonebook {
    .page-title {
        padding-top: 60px;
        padding-bottom: 25px;
        margin: 0 0 18px;
        h2 {
            margin-bottom: 50px !important;
        }
    }
    .inner-box{
        padding-bottom: 10px;
    }
    nav{
        margin-bottom: 70px;
        .pagination .page-item {
    margin: 0 20px;
        }
    }
}
@media screen and (max-width: 767px) {
    .phonebook {
            nav{
        margin-bottom: 70px;
        .pagination .page-item {
    margin: 0 5px;
        }
    }
    }

}