
.newsletter-form{
  padding-left: 50px;
  font-size:rem-calc(14);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.075em;
  h4{
    color: #eb291a;
    font-size:rem-calc(16);
    border-bottom: 1px solid #424242;
    padding-bottom: 34px;
    letter-spacing: 0.075em;
    margin-bottom: 18px;
    &:hover{
      text-decoration: underline;
    }
  }
  label{
    display: block;
    color: #ffffff;
    margin-bottom: 10px;
  }
  input[type="text"]{
    border: 1px solid #424242;
    color: rgba(255,255,255,.28);
    background: #424242;
    outline: none;    
    border-radius: 5px;
    padding: 10px 0px 10px 12px;
    width: calc(100% - 140px);
    margin-right: 8px;
    &:focus{
        border: 1px solid $brand-primary;
    }

    }
  input[type="submit"]{
    border-radius: 5px;
    background: #09a3e0;
    border: none;
    color: #fff;
    padding-top: 10px;
    padding-bottom:10px;
    min-width: 120px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    @include cubic-transition;
    &:hover{
      background: #2fc4ff;
      @include cubic-transition;
    }
  }
}
@media screen and (max-width: 992px) {
  .newsletter-form{
    padding-left: 0;
    h4{
      display: inline-block;
      border-right: 1px solid #424242;
      border-bottom: none;
      vertical-align: top;
      padding-top: 30px;
      padding-right:28px;
    }
    form{
      display: inline-block;
      padding-top: 20px;
      padding-left: 40px;
    }
  }
}
@media screen and (max-width: 767px) {
  .newsletter-form{
    padding-left: 0;
    h4{
      display: block;
      border-bottom: 1px solid #424242;
      border-right: none;
      vertical-align: top;
      padding-top: 30px;
      padding-right:0px;
      text-align: center;
    }
    form{
      display: block;
      padding-top: 20px;
      padding-left: 0px;
    }
    input[type="text"] , input[type="submit"]{
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
