.two-column-layout {
    .column{
        float: left;
        width: 50%;
        padding: 80px 50px 30px 20px;
    }
}


@media(max-width:991px) {
    .two-column-layout{
        .column{
            float: none;
            width: 100%;
            padding: 30px;
        }
    }
}




