.form{
  label{
    display: block;
    color: #ffffff;
    margin-bottom: 10px;
    color: #202020;
    font-size: rem-calc(14);
    font-weight: 400;
  }
  input[type="text"] , input[type="email"]{
    color: #bcbcbc;
    background: #e8e8e8;
    border: none;
    border-radius: 5px;
    padding: 10px 0px 10px 12px;
    width: 100%;
    font-size: rem-calc(14);
    font-weight: bold;
    border: 1px solid #e8e8e8;
    &:focus{
      border:1px solid $brand-primary;
    }
  }
  textarea{
    color: #bcbcbc;
    background: #e8e8e8;
    resize: none;
    min-height: 150px;
    border-radius: 5px;
    border:none;
    padding: 10px 0px 10px 12px;
    border: 1px solid #e8e8e8;
    width: 100%;
        &:focus{
      border:1px solid $brand-primary;
    }
  }
  input[type="submit"]{
    border-radius: 5px;
    background: #09a3e0;
    border: none;
    color: #fff;
    padding-top: 8px;
    padding-bottom:8px;
    min-width: 120px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
        @include cubic-transition;
    &:hover{
      background: #2fc4ff;
      @include cubic-transition;
    }
  }
  select{
    padding-top: 8px;
    padding-bottom:8px;
    width: 100%;
    max-width: 220px;
  }
}
@media screen and (max-width: 767px) {
    input[type="text"] , input[type="submit"]{
      width: 100%;
      margin-bottom: 10px;
    }
  }
