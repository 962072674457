#banner , #pagehead{
    position: relative;
    figure{
        position: relative;
        margin-bottom: 0;
        img{
            object-fit: cover;
            object-position: center center;
            width: 100%;
            max-height: 879px;
        }
        &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0px;
            max-height: 150px;
            background: rgba(248,80,50,1);
            background: -moz-linear-gradient(top, transparent 0%, transparent 46%, rgba(255,255,255,1) 71%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,80,50,1)), color-stop(46%, rgba(241,111,92,1)), color-stop(71%, rgba(246,41,12,1)), color-stop(90%, rgba(240,47,23,1)), color-stop(100%, rgba(231,56,39,1)));
            background: -webkit-linear-gradient(top, rgba(248,80,50,1) 0%, rgba(241,111,92,1) 46%, rgba(246,41,12,1) 71%, rgba(240,47,23,1) 90%, rgba(231,56,39,1) 100%);
            background: -o-linear-gradient(top, rgba(248,80,50,1) 0%, rgba(241,111,92,1) 46%, rgba(246,41,12,1) 71%, rgba(240,47,23,1) 90%, rgba(231,56,39,1) 100%);
            background: -ms-linear-gradient(top, rgba(248,80,50,1) 0%, rgba(241,111,92,1) 46%, rgba(246,41,12,1) 71%, rgba(240,47,23,1) 90%, rgba(231,56,39,1) 100%);
            background: linear-gradient(to bottom, transparent 0%, transparent 10%, #ffffff 40%, #ffffff 70%,#ffffff 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85032', endColorstr='#e73827', GradientType=0 );
        }
    }
    .inner{
        width: 100%;
        @include vertical-center;
        left: 0;
        margin-top: -100px;
        h1{
            text-transform: uppercase;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
            font-size:rem-calc(36);
            letter-spacing: 0.075em;
        }
        p{
            color: #ffffff;
            font-weight: 400;
        }
    }
    &:after{
        background-image: url("../images/page-head.png");
        content: "";
    }
}
#banner {
    figure {
        img {
            max-height: 1166px;
        }
    }
    .inner {
        max-width: 650px;
        left: auto;
        margin-top: 0px;
        h1 {
            text-align: left;
            font-size: rem-calc(60);
        }
    }
}

@media screen and (max-width: 992px) {
    #banner,
    #pagehead {
        figure {
            img {
                min-height: 750px;
            }
        }
        .inner{
            h1{
                font-size:rem-calc(40);
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #banner,
    #pagehead {
        figure {
            img {
                min-height: 600px;
            }
        }
        .inner {
            @include vertical-center;
            h1 {
                font-size: rem-calc(30);
            }
            p {
                font-size: rem-calc(14);
            }
        }
        &:after{
            display: none; 
        }
    }
    #banner .inner{
        left: 0;
        padding: 0px 10px;
    }
    
          #banner figure::after, #pagehead figure::after {display: none;}
}
