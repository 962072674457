.card {
    border: none;
    border-bottom: 1 px solid rgba(80, 109, 123, 0.18);
    .card-header {
        background-color: #fff;
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
        h5 {
            text-transform: uppercase;
            letter-spacing: 0.075e m;
            font-size: rem-calc(16);
            a {
              color: $brand-primary;
              font-weight: 700;
              display: block;
              position: relative;
              &:after{
               content: "\f077";
                font: normal normal normal 18px/1 FontAwesome;
                position: absolute;
                right: 0px;
                color: $brand-primary;
                }
                &.collapsed{
                    color: #3d3d3d;
                    &:after{
                        content: "\f078";
                        color: #3d3d3d;
                    }
                }
            }
        }
    }
    .card-block{
        padding: 0;
    }
}