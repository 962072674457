.phonebook{
    .list2{
        li{
            font-size: rem-calc(14);
            font-weight: 700;
            color: #3d3d3d;
            text-transform: uppercase;
            letter-spacing: 0.075em;
            cursor:pointer;
        }
    }
}