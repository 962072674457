.home {
    #wrapper {
        margin-top: -200px;
    }
    .announcements {
        margin-bottom: 60px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        h2,
        h3 {
            text-transform: uppercase;
            letter-spacing: 0.075em;
            font-weight: 700;
        }
        h2 {
            color: #9c9c9c;
            font-size: rem-calc(16);
            margin-bottom: 50px;
        }
        p {
            font-size: rem-calc(14);
            font-weight: 400;
            color: #4f4f4f;
            margin-bottom: 34px;
        }
        .feeds {
            padding: 30px;
            background: #ffffff;
            margin-bottom: 30px;
            h3 {
                color: #000000;
                font-size: rem-calc(21);
                line-height: rem-calc(30);
                margin-bottom: 25px;
            }
            .btn-primary {
                margin-bottom: 15px;
            }
            &.carousel1 {
                position: static;
                .arrows {
                    width: auto;
                    a {
                        background: #c8cfd3;
                        &:hover {
                            background: #3ab5e6;
                            color: #ffffff;
                        }
                    }
                    transform: unset;
                    .prev-btn,
                    .next-btn {
                        position: static;
                    }
                }
            }
        }
        .links {
            h3 {
                color: #ffffff;
                background: #eb291a;
                font-size: rem-calc(18);
                padding: 30px;
                margin-bottom: 0;
            }
            ul {
                background: #000;
                padding: 30px;
                li {
                    &:hover {
                        a{
                            color: $brand-primary;                             
                        }
                        background-position: right top;
                    }
                    margin-bottom: 20px;
                    letter-spacing: 0.075em;
                    text-transform: uppercase;
                    font-size: rem-calc(14);
                    background-image: url("../images/arrow-sprite.png");
                    background-repeat: no-repeat;                    
                    background-position: right bottom;                    
                    height: 13px;
                    a {
                        color: #ffffff;                        
                    }
                }
            }
        }
        .arrows {
            position: absolute;
            top: 30px;
            right: 30px;
            z-index: 100;
        }
    }
}


@media(max-width:767px) {
    .home{
        .announcements{ 
            margin-bottom: 0;           
            .box2{
                margin-bottom: 40px;
            }
        }
    }
}


@media(max-width:543px) {
    .home{
        .announcements{ 
            margin-bottom: 0;           
            h2{
                padding-right: 65px;
            }
        }
    }
}




.about-section {
    .description {
        padding: 60px 35px 45px 40px;
    }
    article {
        p:last-child {
            border-bottom: 1px solid #dbdbdb;
            padding-bottom: 29px;
            margin-bottom: 20px;
        }
    }
    .sponsors {
        vertical-align: middle;
        position: relative;
        h3 {
            color: rgba(0, 0, 0, .42);
            font-size: rem-calc(18);
            font-weight: 700;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            letter-spacing: 0.075em;
            margin-right: 50px;
        }
        .sponsor-brand {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .slide {
        img {
            width: 100%;
            max-height: 490px;
        }
        ul {
            margin-bottom: 0;
        }
    }
}
@media screen and (max-width: 992px) {
 .about-section {
      .description {
    padding: 55px 45px 40px 45px;
      }
 }   
}
@media screen and (max-width: 767px) {
 .about-section {
        .description {
            padding: 45px 30px 30px 30px;
            h2 {
                margin-bottom: 30px;
            }
        }

    .sponsors {
        text-align: center;
        h3 {
margin-right: 0;        }
    }
 }   
 .home{
     .announcements{
        .feeds{
                        .btn-primary {
                width: 100%;
            }
        }
     }
 }
}



    .region-section {
        margin-bottom: 40px;
        .map1 {
            position: absolute;
            top: -70px;
            left: 20px;
        }
        .two-column-layout {
            width: 800px;
            float: right;
        }
        .selectbox {
            &:after {
                background: url(../images/select1.jpg) no-repeat;
                width: 47px;
                height: 59px;
                content: "";
                position: absolute;
                top: 0; 
                right: 0;
                pointer-events: none;
            }
                box-shadow: -1px -1px 20px -2px rgba(0,0,0,.4);                
                display: block; 
                margin-bottom: 35px;
                position: relative;                     
        }
        select{
                width: 100%;
                padding: 19px 20px;
                text-transform: uppercase;
                border-radius: 3px;
                border: none;
                outline: none;
                height: 59px;
                background: #fff;
                option{
                    font-size: rem-calc(16);
                    font-weight: 500;
                    color: #000;                    
                }
            }
        h2{
            font-size: rem-calc(30);
            color: #000;
        }
    }



@media(max-width:991px) {    
        .region-section{
            .two-column-layout {
                width: auto;
                float: none;
            }
            .map1 {
                > div {margin: 0 auto;}
                position: relative;
                top: auto;
                left: auto; 
                text-align: center;
            }
        }    
}

@media(max-width:767px) {
        .region-section{
            .box1{
                margin-bottom: 0;
            }
        }   
}





