.controls {
	text-align: center;
	position: absolute;
	bottom: 50px;
	width: 100%;
	z-index: 100;
	a {
		border-radius: 50%;
		display: inline-block;
		background: #ffffff;
		height: 6px;
		width: 6px;
		margin: 0px 5px;
		overflow: hidden;
		text-indent: -2000px;
		&:hover {
			background: #09a3e0;
		}
	}
	a.selected {
		background: #09a3e0;
	}
}

/*
  Carousel-Arrows
*/
.arrows {
	.prev-btn {
		background: #c8cfd3;
		height: 23px;
		width: 23px;
		top: 50%;
		border-radius: 50%;
		display: inline-block;
		text-align: center;
		color: #ffffff;
		font-size: rem-calc(22);
		line-height: rem-calc(22);
		outline: none;
		margin-right: 7px;
		&:hover {
			background: #3ab5e6;
		}
	}
	.next-btn {
		background: #c8cfd3;
		height: 23px;
		width: 23px;
		top: 50%;
		border-radius: 50%;
		display: inline-block;
		text-align: center;
		color: #ffffff;
		font-size: rem-calc(22);
		line-height: rem-calc(22);
		&:focus {
			outline: none;
		}
		&:hover {
			background: #3ab5e6;
		}
	}
}


