/*
* Horizontal Line
*/
hr {
    border-top: 1px solid rgba(0, 0, 0, .13);
    height: 1px;
    display: inline-block;
    width: 100%;
    border-color: rgba(0, 0, 0, .13);
    margin: 0;
}
a{
    @include cubic-transition;
    &:hover{
        @include cubic-transition;
    }
}

/*
* Image Alignment
*/
img {
    max-width: 100%;
    &.alignleft {
        float: left;
        margin: 0 35px 35px 0;
        display: inline;
    }
    &.alignright {
        float: right;
        margin: 0 0px 35px 35px;
        display: inline;
    }
    &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
    }
}