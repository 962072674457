#main-navigation {
    padding-top: 32px;
    display: inline-block;
    border-bottom: 1px solid #5f5f5f;
    padding-bottom: 0px;
    position: relative;
    margin-bottom: 10px;
    ul {
        list-style-type: none;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        li {
            float: left;
            position: relative;
            margin: 0px 9px;
            text-transform: uppercase;
            font-size:rem-calc(12);
            font-weight: 500;
            letter-spacing: 0.075em;
            a{
              color: #ffffff;
              padding: 0 7px 15px;
              display: block;
              line-height: 30px;
              &:hover, &.active{
                  color: #09a3e0;
                  text-decoration: none;
              }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    #main-navigation{
        ul{
            li{
               margin: 0px 5px; 
               font-size: rem-calc(11);
               a{
                   padding: 0 5px 15px;
               }
            }
        }
    }

}
@media screen and (max-width: 991px) {
    #main-navigation{
        padding-bottom: 12px;
        padding-top: 17px;
        border-bottom:none;
        margin-bottom: 0;
        ul{
                position: fixed;
                width: 300px;
                top: 0;
                right: -1000px;
                background-color: #000;
                margin-right: 0;
                padding: 110px 10px  30px 25px;                                
                z-index: 1;
                height: 100%;
                background-image: url(../images/mobile-bg.png);
                background-repeat: no-repeat;
                background-position: left bottom;
                background-size: contain;
                overflow-y: auto;
                transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
                &.open{
                    right: 0px;
                    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
                }
                
            li{
                float: none;
                text-align: left;                
                margin-bottom: 15px;
                .fa-angle-down {
                    font-size: 20px; 
                    line-height: 20px;
                    color: #fff;
                    margin-left: 10px;
                }
                a{
                    padding: 0;
                }
            }
        }
    }
}

@media(max-width:767px) {
    #main-navigation {
        ul {
            background-image: url(../images/mobile1-bg.png);
            width: 100%;            
        }
    }
}



.contact{
    a{
        font-weight: 700;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        &:hover{
            text-decoration: underline;
        }
    }
    i{
        display: inline-block;
        margin-right: 10px;
    }
    .email{
        font-size:rem-calc(18);
        border-right: 1px solid #5f5f5f;
        padding-right: 35px;
        margin-right: 28px;
        color: #09a3e0;
    }
    .report{
        font-size:rem-calc(16);
        color: #eb291a;
    }
}
@media screen and (max-width: 991px) {
    .contact{
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        bottom: -62px;
        background: rgba(0,0,0,.35);
        padding-top: 20px;
        padding-bottom: 15px;
    }
}
@media screen and (max-width: 767px) {
    .contact{
        bottom: -59px;
    }
}


#main-navigation {
    .dropdown {
        background: #171717;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 1px solid #5f5f5f;
        position: absolute; 
        top: 45px; 
        right: 0;
        width: 200px;
        padding: 15px 0;
        display: none;
        li {
            &:hover {
                background: #09a3e0;
                a {
                    color: #fff;
                }
            }
            float: none;
            margin: 0;
            padding: 10px 30px;
            text-align: left;
            margin-bottom: 5px;
            a{
                padding: 0;
                line-height: 20px;
            }            
        }
    }
    li {
        
            &:hover {
                .dropdown {
                    display: block;
                }
           
        }
    }
}



#main-navigation{
    .menu-text {
        font-size: rem-calc(20);
        font-weight: 300;
        color: #fff;
        line-height: 25px;
        position: absolute; 
        top: 35px;
        left: 24px;
    }
 
}














