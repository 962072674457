.box1 {
    border-radius: 5px;
    box-shadow: 3px 3px 23px -7px #000000;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 50px;
    background: #ffffff;
    .innercontent {
        padding: 40px;
        header {
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.17);
            margin-bottom: 37px;
            padding-top: 25px;
            padding-bottom: 8px;
        }
        img {
            border-radius: 10px;
        }
        .link {
            font-size: rem-calc(12);
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.075em;
            &:hover{
                text-decoration: underline;
            }
        }
        footer {
            .arrows {
                float: right;
            }
        }
    }
    h2 {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 42px;
        letter-spacing: 0.075em;
    }
    p {
        font-size: rem-calc(14);
        line-height: rem-calc(24);
        font-weight: 400;
        color: #4f4f4f;
        margin-bottom: 30px;
    }

}
.metadata{
    color: #595959;
    font-size: rem-calc(12);
    display: inline-block;
}

.box2 {
    border-radius: 5px;
    box-shadow: 3px 3px 23px -7px #000000;
    overflow: hidden;
    margin-bottom: 50px;
}
@media screen and (max-width: 992px) {
    .box1 {
        .innercontent {
            padding: 30px;
            header {
                text-align: left;
                margin-bottom: 20px;
                padding-top: 0px;
                padding-bottom: 20px;
                .metadata {
                    margin-bottom: 0;
                }
            }
        }
    }
}