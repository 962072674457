.post{

                h3 {
                text-transform: uppercase;
                color: #3d3d3d;
                font-weight: 700;
                letter-spacing: 0.075em;
            }
                p {
        font-size: rem-calc(14);
        line-height: rem-calc(24);
        font-weight: 400;
        color: #4f4f4f;
        margin-bottom: 30px;
    }
}
