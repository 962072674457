.media-item {
    .box1 {
        .page-title {
            margin: 0px -40px 50px;
            text-align: center;
            box-shadow: inset 5px 30px 20px -11px rgba(0, 0, 0, 0.1);
        }
        .innercontent {
            header {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
                .link {
                    float: left;
                }
                .arrows {
                    float: right;
                }
            }
        }
        .controls {
            bottom: -25px;
            a {
                background: #d8d8d8;
                &.activeSlide {
                    background: #787878;
                }
            }
        }
    }
}