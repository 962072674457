.about{
    #pagehead {
        figure img {
            max-height: 705px;
        }
    }
#pages .page-content {
    display: block;
}
#pages{
    .pages-list {
    display: inline-block;
    width: 100%;
    text-align: center;
    li{
        padding: 0px 38px;
        width: auto;
    }
}
 .page-content {
    display: block;
}   
.page-title {
    padding: 53px 0;
    margin: 0 0 35px;
}
}
.committee {
    .row {
        margin-right: -20px;
        margin-left: -20px;
        .col-md-4 {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
    .box1{
        figure{
            margin-bottom: 0;
            img{
                width: 100%;
            }
        }
        .details{
            text-align: center;
            padding-top: 10px;
            h3{
                color:#4f4f4f;
                font-size: rem-calc(14);
                font-weight: 700;
                letter-spacing: 0.075em;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            span{
                display: block;
                font-size: rem-calc(14);
                font-weight: 400;
            } 
            a{
                display: inline-block;
                font-size: rem-calc(14);
                font-weight: 400;
                color:#4f4f4f;
                margin-bottom: 10px;
            } 
        }
    }
}
#flags{
    .page-title {
        padding-bottom:130px;
        margin: 0 0 35px;
    }
.flags {
    margin-top: -145px;
    figure {
        img {
            margin-bottom: 30px;
            display: inline-block;
            border-radius: 5px;
            width: 100%;
        }
        figcaption {
            text-align: center;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
        }
    }
}
}

#vehicles{
    .page-title {
        padding-bottom:130px;
        margin: 0 0 35px;
    }
.vehicles {
    margin-top: -145px;
    figure {
        img {
            margin-bottom: 30px;
            display: inline-block;
            border-radius: 5px;
            width: 100%;
        }
        figcaption {
            text-align: center;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
        }
    }
}
}
.carousel1 {
    max-width: 565px;
    margin: 0 auto 70px;
    figure {
        img {
            margin-bottom: 30px;
            display: inline-block;
            border-radius: 5px;
            width: 100%;
        }
        figcaption {
            text-align: center;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
            letter-spacing: 0.075em;
        }
    }
    h3{
        text-align: center;
        font-weight: 700;
        color: #3d3d3d;
        text-transform: uppercase;
        letter-spacing: 0.075em;
        margin-bottom: 30px;
    }
    .arrows {
        a {
            background-color: #dddddd;
            color: #fff;
        }
        .next-btn {
            right: -70px;
        }
        .prev-btn {
            left: -70px;
        }
    }
}
.carousel2 {
    margin: 25px auto 70px;

    figure {
        img {
            margin-bottom: 30px;
            display: inline-block;
            border-radius: 5px;
            width: 100%;
        }
        figcaption {
            text-align: center;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
            letter-spacing: 0.075em;
        }
    }
    h3{
        text-align: center;
        font-weight: 700;
        color: #3d3d3d;
        text-transform: uppercase;
        letter-spacing: 0.075em;
        margin-bottom: 30px;
    }
    .arrows {
        a {
            background-color: #dddddd;
            color: #fff;
        }
        .next-btn {
            right: -70px;
        }
        .prev-btn {
            left: -70px;
        }
    }
}


.exhibition{
    .logo{
        margin-top: -130px;
    }
    h3{
        color: #000000;
        font-size: rem-calc(18);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.075em;
    }
}
}

@media (max-width: 992px) {
    .about {
        #pages {
            .pages-list {
                li {
                    padding: 0px 10px;
                }
            }
        }
        .exhibition{
    .logo{
        margin-top: 0px;
    }
}
.carousel1 {
        .arrows {
            .next-btn {
            right: 10px;
        }
        .prev-btn {
            left: 10px;
        }
}
}
    }
}
@media (max-width: 767px) {
    .about {
.carousel1 {
        .arrows {
            position: static;
            text-align: center;
            margin-top: 20px;
            .next-btn , .prev-btn {
            position: static;
            display: inline-block;
        }
}
}
.carousel2 {
    ul{
        li{
            margin-right: 0;
        }
    }
        .arrows {
            position: static;
            text-align: center;
            .next-btn , .prev-btn {
            position: static;
            display: inline-block;
        }
}
}
    }
}