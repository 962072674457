.contact {
    #pagehead figure::after {
        display: none;
    }
}
.contact-page {
    .box1 {
        padding: 38px 40px 35px 40px;
        position: relative;
        z-index: 100;
        margin: 5px 0px -50px 0px;
    }
    .list3 {
        padding: 0;
        li {
            &.captcha {
                .captcha-image {
                    position: absolute;
                    right: -126px;
                }
                border-top: none;
                border-bottom: none;
                padding-top: 0px;
                margin-bottom: 0px;
                input[type="text"] {
                    max-width: 100%;
                    width: 100%;
                    margin-left: 0px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .contact-page {
        .box1 {
            padding: 45px 40px 55px 40px;
        }
    }
}
@media screen and (max-width: 767px) {
    .contact-page {
        .box1 {
            padding: 30px 50px 53px 40px;
        }
        .list3 {
            li {
                &.captcha {
                    .captcha-image {
                        position: static;
                    }
                    border-top: none;
                    border-bottom: none;
                    padding-top: 0px;
                    margin-bottom: 0px;
                    input[type="text"] {
                        max-width: calc( 100% - 132px);
                        width: 100%;
                        margin-left: 0px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
.contact-info {
    padding-bottom: 33px;
    margin-bottom: 45px;
    color: #2a2a2a;
    letter-spacing: 0.075em;
    .container {
        max-width: 610px;
    }
    h3 {
        color: #000000;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 60px;
        padding-top: 70px;
    }
    a {
        display: block;
        color: #2a2a2a;
        font-size: rem-calc(18);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 45px;
        margin-bottom: 18px;
        &:hover {
            color: $brand-primary;
            &.phone, &.fax, &.mail {
                background-position: left top;
            }
        }
        &.phone {
            background-image: url("../images/phone-sprite.png");
            background-position: left bottom;
            height: 20px;
            transition: none;
        }
        &.fax {
            background-image: url("../images/fax-sprite.png");
            background-position: left bottom;
            height: 19px;
            transition: none;
        }
        &.mail {
            background-image: url("../images/email-sprite.png");
            background-position: left bottom;
            height: 14px;
            transition: none;
        }
    }
    address {
        &:hover {
            color: #09a3e0;
            background-position: left top;
        }
        font-size: rem-calc(16);
        background-image: url("../images/home-sprite.png");
        background-position: left bottom;
        height: 19px;
        background-repeat: no-repeat;        
        padding-left: 45px;
        margin-bottom: 25px;
    }
}
@media screen and (max-width: 992px) {
    .contact-page{
    #wrapper {
        margin-top: -320px;
    }
    }
}
@media screen and (max-width: 767px) {
    .contact-info {
        h3 {
            font-size: rem-calc(20);
        }
    }
}