.page-title {
  background: #f2f2f2;
  padding: 40px 0;
  margin: 0 0 25px;
  h2 {
    margin: 0 !important;
    text-align: center;
    text-transform: uppercase;
    font-size: rem-calc(24);
    letter-spacing: 0.75px;
  }
}
@media screen and (max-width:767px){
  .page-title {
    h2 {
      font-size: rem-calc(20);
    }
  }
}