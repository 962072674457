#header{
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(#000000, 0.5);
  z-index: 999;
  max-height: 132px;
  .container-fluid{
    padding-left: 0;
    text-align: right;
    padding-right: 60px;
  }
}
@media screen and (max-width: 1199px) {
    #header{
  .container-fluid{
    padding-right: 10px;
  }
}
}
