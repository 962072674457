@media (max-width:767px){
  #wrapper {
    margin-top: -275px;
  }
}

/*
*page-head
*/
#pagehead {
  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    h1 {
      text-align: center;
    }
  }
}

/*
*icons
*/
.plus-icon {
  background: url("../images/plus-icon.png");
  display: inline-block;
  vertical-align: middle;
  width: 45px;
  height: 45px;
}

.video-icon {
  background: url("../images/video-icon.png");
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
}

/*
*portfolio
*/
.portfolio {
  .figure1 {
    border-radius: 5px;
    position: relative;
    margin-bottom: 25px;
    text-align: center;
    display: block;
      &:hover {
        .overlay {
          opacity: 1;
          @include cubic-transition;
        }
      }
    .image {
      width: 100%;
      box-shadow: #e5e5e5 0px 0 22px 7px;
    }
    .icon {
       @include absolute-center;
    }
  }
  hr {
    border-color: #f9fafa;
    margin: 30px 0;
  }
  .overlay {
    border-radius: 5px;
    background: rgba(9,163,224,0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include cubic-transition;
  }
    h3 {
      text-transform: uppercase;
      color: #1b1b1b;
      font-size: rem-calc(14);
      letter-spacing: 0.75px;
      font-weight: 700;
      margin-bottom: 5px;
      text-align: center;
    }
    .metadata {
      display: block;
      font-weight: 400;
      color: #595959;
      font-size: rem-calc(12);
      text-align: center;
    }
}

    #videos {
      .overlay {
        opacity: 1;
        background: rgba(0,0,0,0.5);
        @include cubic-transition;
      }
      .figure1 {
        &:hover {
          .overlay {
             background: rgba(9,163,224,0.9);
             @include cubic-transition;
          }
        }
      }
    }

@media (max-width:767px){
  .portfolio {
    .metadata {
      margin-bottom: 20px;
    }
    hr {
      margin: 0 -30px 30px;
    }
  }
}
