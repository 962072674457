.events-calendar { background: #f9fafb; }
.events-calendar .calendar { margin-bottom: 32px; }
.events-calendar .dot { display: inline-block; width: 13px; height: 13px; margin-right: 10px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; behavior: url("assets/javascripts/vendor/PIE.htc"); position: relative; }
.events-calendar .dot.yellow { background: #face20; }
.events-calendar .dot.red { background: #ef4035; }
.events-calendar .dot.blue { background: #89d7f7; }



.ui-datepicker { background: #ffffff; border: 1px solid #d8dde2; padding: 0px 15px 15px; }
.ui-datepicker .ui-datepicker-header { position:relative; padding: 30px 0 26px; border-bottom:1px solid #e5e7e9; margin: 0px -15px 15px; }
.ui-datepicker .ui-datepicker-header a { background:url("../images/theme/calendar-arrows.png") no-repeat; width:52px; height:52px; display:block; cursor:pointer; position:absolute; top: 18px; }
.ui-datepicker .ui-datepicker-header a.ui-datepicker-prev { left:20px; }
.ui-datepicker .ui-datepicker-header a.ui-datepicker-next { right:20px; background-position:right top; }
.ui-datepicker .ui-datepicker-header a span { display:none; }

.ui-datepicker .ui-datepicker-calendar thead { display: none; }

.ui-datepicker .ui-datepicker-title { text-align: center; color: #242626; font-size: 26px; line-height: 30px; font-weight:300; text-transform:uppercase; }
.ui-datepicker .ui-datepicker-calendar { width: 100%; }
.ui-datepicker .ui-state-default { font-size: 18px; line-height: 21px; font-weight:normal; color:#abb5b9; min-height: 120px; padding: 10px; display: block; }
.ui-datepicker .ui-state-default.ui-state-active { background:#ddf1fa; color:#254a58; position:relative; }
.ui-datepicker .ui-datepicker-calendar td { border-top: 1px solid #eaeced; border-left: 1px solid #e7e8ea; }
.ui-datepicker .ui-datepicker-calendar tr:first-child td { border-top: 0px; }
.ui-datepicker .ui-datepicker-calendar td:first-child { border-left: 0px; }

.ui-datepicker-trigger { position: absolute; right: 6px; top: 14px; cursor: pointer; }

.event-details { background: #183d4c; padding: 20px; text-align: center; display:none; position:absolute; left:0; width: 195px; z-index: 9; }
.event-details:before { content: ''; pointer-events: none; height: 0px; width: 0px; border: 11px solid transparent; border-bottom-color: #183d4c; position: absolute; left: 50%; margin-left: -11px; top: -22px; }
.event-details p { font-size: 14px; line-height: 21px; color: #ffffff; margin-bottom: 10px; }
.event-details a { font-size: 12px; line-height: 15px; font-weight: 700; color: #89d7f7; text-transform: uppercase; }
@media screen and (max-width:991px) {
	.events-calendar .calendar { margin-bottom: 22px; }
	.events-notification { margin-bottom: 0px; }
	
	.ui-datepicker { padding: 0px 0px 15px; }
	.ui-datepicker .ui-datepicker-header { margin: 0px 0px 15px; }
	.ui-datepicker .ui-state-default { min-height: 101px; }
}
@media screen and (max-width:767px) {
	.events-notification li { display: block; margin-left: 0px; margin-bottom: 20px; }
	.events-calendar .ui-datepicker { margin: 0 -15px; }
	
	.ui-datepicker .ui-datepicker-header a { top: 13px; }
	.ui-datepicker .ui-datepicker-header a.ui-datepicker-prev { left: 15px; }
	.ui-datepicker .ui-datepicker-header a.ui-datepicker-next { right: 15px; }
	.ui-datepicker .ui-datepicker-title { font-size: 18px; line-height: 22px; }
	.ui-datepicker .ui-state-default { min-height: 45px; text-align: center; }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),  only screen and ( min--moz-device-pixel-ratio: 2),  only screen and ( -o-min-device-pixel-ratio: 2/1),  only screen and ( min-device-pixel-ratio: 2),  only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
	.ui-datepicker .ui-datepicker-header a { background:url("../images/theme/calendar-arrows@2x.png"); background-size: 104px 52px; }
}


/*
*Events Calendar
*/


	
#events-calendar { background: #ffffff; position:relative; }
#events-calendar table {  width:100%; }
#events-calendar table th {  position:relative;  }
#events-calendar table th .heading { position:relative; padding: 30px 0 26px; text-align: center; color: #000; font-size: 24px; line-height: 28px; font-weight:700; text-transform:uppercase; }
#events-calendar table td  { border: 1px solid #e1e1e1;  position:relative; }
#events-calendar table td a { font-size: 18px; line-height: 21px; font-weight:normal; color:#abb5b9; min-height: 120px; padding: 10px; display: block;  }

#events-calendar table .last td {border-bottom: none;}



#events-calendar table .week-days td {font-size: rem-calc(14); font-weight: 700; color: #000; padding: 20px; text-align: center; text-transform: uppercase; background: #f7f7f7; border-top: 1px solid #e1e1e1; border-bottom:1px solid #e1e1e1; border-right: 1px solid #e1e1e1; position: static;}
#events-calendar table .week-days td:last-child { border-right: none;}


#events-calendar table td a.active { background:#ddf1fa; color:#254a58; position:relative; }
#events-calendar table td .dot { display: inline-block; width: 13px; height: 13px; margin-right: 10px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; behavior: url("assets/javascripts/vendor/PIE.htc"); position: absolute; top:40px; cursor:pointer; }
#events-calendar table td .dot.red { background: #ef1a1a; left:15px; }
#events-calendar table td .dot.blue { background: #09a3e0; left:10px; }
#events-calendar table td .dot.red + .dot.blue {left: 33px;}
#events-calendar .event-details { border-radius: 3px; background: rgba(0,0,0,.7); padding: 15px; text-align: center; display:block; position:absolute; left:-85px; width: 221px; z-index: 9; top: -100px; }
#events-calendar .event-details:before { content: ''; pointer-events: none; height: 0px; width: 0px; border: 11px solid transparent; border-top-color: rgba(0,0,0,.7); position: absolute; left: 50%; margin-left: -11px; top: 119px; }
#events-calendar .event-details p { font-size: 14px; line-height: 21px; color: #ffffff; margin-bottom: 10px; font-weight: 400; }
#events-calendar .event-details a { font-size: 13px; line-height: 17px; font-weight: 700; color: #09a3e0; text-transform: uppercase; min-height:inherit; }

#events-calendar .arrows a {display: none;}

@media (max-width: 992px) {
	#events-calendar table td a { min-height:90px; }
}
@media (max-width: 767px) {
	.events-calendar { background: #fff; }
	#events-calendar { padding:0px; border:none;}
	#events-calendar table td .dot.red { background: #ef4035; left:60px; right:auto; top: 20px; }
	#events-calendar table td .dot.blue { background: #89d7f7; left:60px; right:auto; top: 20px;}
	#events-calendar table td .dot.red + .dot.blue {display: none;}
	#events-calendar table td { display:none;}
	#events-calendar table td .dot { top:10px; }
	#events-calendar table td.events { display:block; width:100%; border:none;  border-top: 1px solid #e7e8ea; border-bottom: 1px solid #e7e8ea; }
	#events-calendar table th .heading { border-bottom:none; }
	
	#events-calendar table th .heading { font-size:20px; }
	

    #events-calendar .event-details { left: 90px; top: 0; width: 400px; background: #fff; padding: 10px; text-align: left;}
    #events-calendar .event-details:before { display: none;}
    #events-calendar .event-details p { color: #000;}
    #events-calendar .event-details a {padding: 0;}
    #events-calendar .arrows .prev-btn { position: absolute; top: 50%; left: 10%; margin-top: -10px; z-index: 15; display: block;}
    #events-calendar .arrows .next-btn { position: absolute; top: 50%; right: 10%; margin-top: -10px; z-index: 15; display: block;}
}


@media(max-width:543px) {
    #events-calendar table td .dot.red {left: 40px; top: 15px;}
    #events-calendar table td .dot.blue {left: 40px; top: 15px;}
    #events-calendar .event-details {left: 55px; width: 235px;}
}


.events-notification .dot { display: inline-block; width: 13px; height: 13px; margin-right: 10px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; behavior: url("assets/javascripts/vendor/PIE.htc"); position: relative; }

.events-notification { margin-bottom: 35px; }
.events-notification ul { margin: 0px; padding: 0px; list-style: none; }
.events-notification li:first-child { margin-left: 0px; }
.events-notification li { display: inline-block; margin-left: 50px; font-size: 16px; line-height: 20px; font-weight: 400; color: #434545; }
.events-notification li .dot.red  { background: #ef4035; }
.events-notification li .dot.blue {background: #89d7f7; }




