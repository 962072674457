.newsletter {
    .container {
        max-width: 560px;
    }
}
 .list3 {
        margin: 0;
        padding: 40px 50px 25px 50px;
        list-style-type: none;
        li {
            margin-bottom: 15px;
            &.captcha {
                border-top: 1px solid rgba(0, 0, 0, .12);
                border-bottom: 1px solid rgba(0, 0, 0, .12);
                padding-top: 30px;
                margin-bottom: 30px;
                label {
                    margin-bottom: 20px;
                }
                input[type="text"] {
                    max-width: 200px;
                    margin-left: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
