/*
*news
*/
.news {
  h6 {
    font-weight: 700;
    margin-bottom: 15px;
  }
  h4 {
    margin-bottom: 21px;
  }
  hr {
    margin: 35px 0;
    border-color: #f6f6f6;
  }
}
  .article-share{
    span{
      color: rgba(61,61,61,.46);
      font-size: rem-calc(14);
      font-weight: 700;
      letter-spacing: 0.075em;
      display: inline-block;
      margin-right: 20px;
    }
  }
@media screen and (max-width: 767px) {
    .news-article {
        .article-share {
            border-top: 1px solid #cfcfcf;
            border-bottom: 1px solid #cfcfcf;
            padding-top: 30px;
            padding-bottom: 30px;
            span {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}