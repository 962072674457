.list1 , .list1 li > ul {
    margin: 0;
    padding:0;
    list-style-type: none;
}
.list1{
    >li{
        font-size: rem-calc(14);
        color: #4f4f4f;
        background: rgba(242,242,242,.73);
        margin-bottom: 10px;
        a {
            color: inherit;
        }
        ul{
            >li{
                float: left;
                width: 20%;
                text-align: center;
                padding-top: 10px;
                &:first-child{
                    text-align: left;
                    background-image: url("../images/pdf-icon.png");
                    padding-left: 40px;
                    background-repeat: no-repeat;
                    background-position: 12px 12px;
                    font-weight: 600;
                    width: 40%;
                }
                &:last-child{
                    text-align: right;
                    padding-top: 0px;
                }
            }
        }
&:hover{
    background: #e9e9e9;
    .download-file {
        background-position: right bottom;
    }
}
    }
    
}
.download{
    h3{
        font-size: rem-calc(16);
        font-weight: bold;
        color: #3d3d3d;
        margin-bottom:50px;
        text-transform: uppercase;
    }
}
@media screen and (max-width:767px){
    .list1{
    >li{
        background: rgba(242,242,242,.73);
        margin-bottom: 10px;
        ul{
            padding: 0 20px;
            position: relative;
            >li{
                float: left;
                width: 50%;
                text-align: center;
                padding-bottom: 10px;
                                &:nth-child(1){ width: 80%; text-align: left;
                                    padding-left: 25px;
                                    background-position: left 0px top 15px;
                                }
                                &:nth-child(2){ width: 20%; text-align: right;}
                                &:nth-child(3){ width: 80%; text-align: left;}
                                &:nth-child(4){ width: 20%; text-align: right;}
            }
            &:after{
                content: "";
                width: 100%;
                background: #d8dde0; 
                height: 1px; 
                position: relative;
                bottom: 55px;
                
            }
        }

    }
}
}

.download-file{
    background: url(../images/download-file.jpg) no-repeat;
    width: 40px;
    height: 40px;
    background-position: right top;
}






