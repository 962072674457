.news{
    .row{
        margin-bottom: 45px;
    }
    .box1{
        overflow: inherit;
        .inner-content{
            padding-right: 60px;
            padding-left: 30px;
        }
    }
    .image{
        margin: 0;
        img{
            object-fit: cover;
            object-position: top center;
            width: 100%;
            border-top-left-radius:10px;
            border-bottom-left-radius:10px;
        }
    }
    .inner-content{
    //@include  vertical-center;
    h3{
       letter-spacing: 0.075em;
       color: #000000;
       font-weight: 700;
       text-transform: uppercase;
       margin-bottom: 15px;
    }
    .metadata{
        margin-bottom: 15px;
    }
    h5{
        color: #9c9c9c;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 40px;
    }
    p{
        color: #4f4f4f;
        font-size: rem-calc(14);
        margin-bottom:15px; 
    }
    }
}
.news-article{
    #wrapper {
        margin-top: -517px;
    }
    .post{
        margin-bottom: 85px;
    }
    .arrows .prev-btn, .arrows .next-btn {
            background: #dddddd;
            height: 28px;
            width: 28px;
            font-size: rem-calc(26);
            line-height:rem-calc(28);
                &:hover {
                background: #3ab5e6;
            }
}
}
@media screen and (max-width:992px){
    .news-article{
        .box1{
            .innercontent .post{
                img{
                    float: none;
                    width: 100%;
                    margin: 0 -15px 10px -15px; 
                    display: block;
                    border-radius:0; 

                }
            }
        }
    }
}