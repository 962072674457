.query-section {
    background: rgba(0, 0, 0, .4);
    padding: 25px;
    font-size: rem-calc(14);
    border-radius: 5px;
    .inner-container {
        max-width: 535px;
        h2 {
            font-size: rem-calc(14);
            font-weight: 700;
            color: #ffffff;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        h3,h4,address,a,span {
            font-size: rem-calc(14);
            color: rgba(255, 255, 255, .7);
        }
        address {
            margin-bottom: 0;
        }
    }
}