/*
* popup
*/

.popup {     
    .modal-dialog {
        max-width: 625px;
        transform: translate(0px, -25%);
        transition: transform 0.3s ease-out 0s;
    }
    .modal-content {
        border: none;
        border-radius: 5px;
        position: relative;
    }   
    .member-pic {
        width: 30%;
        background: #f3f3f3;
        padding: 40px 25px; 
        float: left;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        min-height: 313px;
    }
    .member-info{
        width: 70%;
        float: left;
        padding: 45px 30px 20px;
        min-height: 313px;
    }
    .close-btn {                 
         position: absolute; 
         top: -20px; 
         right: -25px;
         opacity: 1;
    }
    h4, h5{
        font-weight: 700; 
        font-size: rem-calc(18); 
        margin-bottom: 15px; 
        letter-spacing: 1px;
    }
    p {
        font-weight: 400; 
        font-size: rem-calc(14); 
        color: #3d3d3d;
    }
    ul {
        margin-bottom: 15px;
        li {
            font-size: rem-calc(14);
            color: #3d3d3d;
            padding: 10px 0;
            border-bottom: 1px solid #c2ccd1;
            span {
                font-weight: 700; 
                min-width: 140px; 
                display: inline-block;               
            }
        }
    }
}

body.modal-open {
    .popup {
        background: rgba(0, 0, 0, 0.5); 
        overflow-x: hidden;
        overflow-y: hidden;
        display: block;
        opacity: 1;
    }
    .modal-dialog {
        position: absolute; 
        top: 50%; 
        left: 50%; 
        width: 100%; 
        height: 100%;
        margin-left: -313px; 
        margin-top: -100px;
        transform: translate(0px, 0px); 
        transition: transform 0.3s ease-out 0s;
    }
}

@media(max-width:767px) {
    .popup {
        .member-pic{
            width: 25%;
        }
        .member-info {
            width: 75%;
            padding: 45px 20px 20px;
        }
        .modal-dialog {
            max-width: 480px;
        }
    }
    body.modal-open {
        .modal-dialog {
            margin-left: -255px;
        }
    }
}

@media(max-width:543px) {
    .popup{
        .member-pic{
            float: none;
            width: 100%;
            min-height: auto;
            text-align: center;
            border-top-right-radius: 5px;
        }
        .member-info {
            float: none;
            width: 100%;
            min-height: auto;
            padding: 20px;
        }
        ul {
            li {
                span{
                    display: block; width: 100%;
                }
            }
        }        
    }
    body.modal-open {
        .popup {
                overflow-y: auto;
                padding: 25px 40px 25px 30px;
                .modal-dialog{
                    max-width: 100%;
                    width: 100%;
                }
            }
        .modal-dialog {
            position: relative;
            top: 25%;
            left: 0px;
            margin: 0;            
            }
        }
}



