.menu-btn {
    &:hover {
        span.icon-bar {
            background: #09a3e0;
        }
    }
    height: 33px;
    width: 43px;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    margin-top: 0;
    border: 0px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 32px;
    position: relative;
    right: 0;
    z-index: 100;
    display: none;
    span.icon-bar {
        display: block;
        background: #ffffff;
        cursor: pointer;
        height: 4px;
        width: 40px;
        margin-top: 9px;
        position: relative;
        transform: rotate(0deg);
        &:first-child {
            margin-top: 0;
        }
    }
    &.active {position: fixed;}
    &.active {        
        span.icon-bar {
            &:first-child + span {
                background-color: transparent;
                opacity: 0;
            }
            &:first-child {
                transform: rotate(45deg);
                left: -1px;
                top: 13px;
            }
            &:last-child {
                transform: rotate(-45deg);
                left: -1px;
                top: -13px;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .menu-btn {
        display: block;
    }
}