.map{
    position: relative;
    box-shadow: 19px 1px 10px -7px #000000;
    figure{
        margin-bottom: 0;
    }
    .map-image{
        img{
            object-fit: cover;
            object-position: center center;
            width: 100%;
            max-height: 485px;
        }
    }
    .marker{
        @include absolute-center;
    }
}