#container {
  min-width: 320px;
  overflow-x:auto;
}

#content {
  background-image: url("../images/content-bg.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

#wrapper {
  margin-top: -360px;
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 992px) {
  #wrapper {
      margin-top: -180px;
   } 
}



body , #container {
    min-width: 320px;
    overflow-x: hidden;
}
html, body {
  height: 100%;
  margin: 0;
}
#container{
  min-height: 100%;
  margin-bottom: -287px;
}
#content{
    height: 100%;
}
#footer ,.push{
  height: 287px;
}

.two-columns{
  -moz-column-width: 2;
  -moz-columns: 2;
  -webkit-columns: 2;
  columns: 2;
}
@media screen and (max-width:992px){
.two-columns{
  -moz-column-width: 1;
  -moz-columns: 1;
  -webkit-columns:1;
  columns: 1;
}
}

@media screen and (max-width:992px){
    #container{
     margin-bottom: 0px;
     min-height: auto;
}
#footer, .push {
    height: auto;
}
}