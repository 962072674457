
#pagehead {
    .container{
        h1 {
            span{
                font-size: rem-calc(16);
                margin-bottom: 16px;
                line-height: 1.25;
                display: block;
            }
        }
    } 
}

.box1.region-section {
    overflow: visible;
    .two-column-layout {
        margin-bottom: 70px;
    }
}

@media(max-width:991px) {
    .box1.region-section {
        .two-column-layout{
            margin-bottom: 15px;
        }
        .selectbox {
            float: left; width: 50%;
        }
        .column {
            p {
                width: 50%;
                float: left;
                padding-left: 20px;
            }
        }
    }
}

@media(max-width:767px) {
    .box1.region-section {
        .selectbox{
            float: none;
            width: 100%;
        }
        .column {
            p {
                float: none;
                width: 100%;
            }
        }
    }
}


.states {
    ul {                
        padding: 0 10px 10px;
    }
    li {
        &:nth-child(3n) {
            border-right: none;
        }
        width: 33.3%;
        float: left;
        border-right: 1px solid #dfe5e7;
        border-top: 1px solid #dfe5e7;
        padding: 40px 10px;
        figure{
            text-align: center;
            figcaption{
                padding-top: 30px;
                font-weight: 700; 
                font-size: rem-calc(18);
                color: #000;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
    }
}

@media(max-width:767px) {
    .states{
        li{           
            &:nth-child(3n) {
                border-right: 1px solid #dfe5e7;
            }
            &:nth-child(2n) {
                border-right: none;
            } 
            width: 50%;
        }
    }
}

@media(max-width:543px) {
    .states{
        li{
            &:nth-child(3n) {
                border-right: none;
            }
            width: 100%;
            border-right: none;
        }
    }
}

/***********************************************************/

.box1.contact-information {
    margin-bottom: 90px;
}

.carousel3 {
    .arrows {
        a{
            display: none;
        }        
    }
}

@media(max-width:991px) {
    .carousel3 {
        .arrows {
            a{
                display: inline-block !important;
            }        
        }
    }
}

.contact-information {
    padding: 50px 50px 60px 50px;
    h3{
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 20px;
        a{
            font-weight: 400; 
            font-size: rem-calc(16);
            color: #506d7b;
        }
        i{
            margin-right: 15px;
        }
    }
}

@media(max-width:991px) {
    .contact-information {
        .col-md-9 {
            padding-left: 50px;
        }
    }
}

@media(max-width:767px) {
    .contact-information {
        padding: 50px 20px;
        p {
            i {
                margin-right: 10px;
            }
        }
        .col-md-3 {
            text-align: center;
            img {margin-bottom: 40px;}
        }
        h3{
            text-align: center;
        }
        .col-md-9 {
            padding-left: 0px;
        }
    }
}



.about-department {
    width: 800px;
    max-width: 100%;
    margin-bottom: 90px;
    h3{
        font-size: rem-calc(30);
        margin-bottom: 20px;
        color: #000; 
        text-transform: uppercase;
        font-weight: 700;
    }
    p {
        margin-bottom: 25px;
        font-weight: 400; 
        font-size: rem-calc(14);
        color: #4f4f4f;
     }
}

@media(max-width:991px) {
    .about-department {
        width: auto;
    }
}


.our-members {
    padding: 50px 20px 40px;
    margin-bottom: 90px;
    h3{
        font-weight: 700;
        font-size: rem-calc(30);
        text-transform: uppercase;
        margin-bottom: 35px;
        text-align: center;
    }
    li {
        &:last-child{
            border-right: none;
        }
        float: left;
        width: 300px;
        border-right: 1px solid #dae0e3;
        height: 425px;        
    }
    .member-info {
        padding: 0 50px ;
        figure {
            margin-bottom: 30px;          
        }
        p {
            font-size: rem-calc(14);
            color: #000;
            margin-bottom: 15px;
        }
    }
}

@media(max-width:991px) {
    .our-members{
        position: relative;
        &:after {
            background: #fff;
            height: 435px;
            width: 40px;
            content: "";
            position: absolute; 
            top: 110px;
            right: 10px;
        }
    }
}

@media(max-width:767px) {
    .our-members{
        &:after {
            display: none;
        }
        li {
            border-right: none;
        }
    }
}


.stat {
    margin-bottom: 90px;
    li {        
        &:nth-child(3n){
            border-right: none;
        }
        &:hover {
            color: #09a3e0;
        }
        float: left;
        width: 33.3%;
        border-right: 1px solid #b7b7b7;
        border-bottom: 1px solid #b7b7b7;
        min-height: 190px;
        font-size: rem-calc(13);
        font-weight: 500;
        color: #000;
        text-align: center;
        padding: 30px;
        span{
            display: block;
            font-weight: 200;
            font-size: rem-calc(60);
            line-height: 1.16;
            margin-bottom: 20px;            
        }
    }
}

@media(min-width:768px) {
    .stat {
        li {
            &.border-bottom  {
                border-bottom: none;
            }
        }
    }
}

@media(max-width:767px) {
    .stat {
        li {
            &:last-child {
                border-bottom: none;
            }
            float: none;
            width: 100%;
            min-height: auto;
            padding: 20px;
            border-right: none;
            span{
                font-size: rem-calc(48);
                line-height: 1.125;
            }
        }
    }
}

.equipment {
    h3{
        font-weight: 700;
        font-size: rem-calc(30);
        text-transform: uppercase;
        margin-bottom: 35px;
        text-align: center;
    }    
    li {
        float: left;
        width: 293px;
        margin-right: 20px;
        margin-bottom: 30px;
        height: 255px;
        box-shadow: 2px 2px 9px 3px rgba(0, 0, 0, 0.2);
        figure{
            margin-bottom: 0;
            img{
                width: 100%;
            }
            figcaption{
                background: #fff;
                font-weight: 700; 
                font-size: rem-calc(18);
                color: #000;
                letter-spacing: 1px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                text-transform: uppercase;
                padding: 20px;
                text-align: center;
            }
        }
    }
}

@media(max-width:991px) {
    .equipment{
        .arrows{
            a {
                display: none !important;
            }
        }
        li {
            width: 320px;
            height: 280px;
        }
    }
}

@media(max-width:767px) {
    .equipment{
        margin-bottom: 30px;
        .arrows{
            a {
                display: inline-block !important;
            }
        }
        li {
            box-shadow: none;
        }
    }
}

.section1{
    border-top: 1px solid #c7d0d5;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    img, span {
        display: inline-block;
        vertical-align: middle;
    }
    span {
        font-weight: 700;
        margin-left: 25px; 
        font-size: rem-calc(14);
        color: #000;
        a{
            padding-top: 5px;
            font-weight: 400;
            color: #09a3e0;
            display: block;
        }
    }
}


