/*
*pages
*/
#pages {
  .pages-list {
    margin: 0;
    padding: 0;
    box-shadow: 5px 2px 12px 11px rgba(0, 0, 0, 0.1);
    position: relative;
    li {
      list-style: none;
      width: 50%;
      display: inline-block;
      margin: 0 -2px;
      letter-spacing: 0.75px;
      color: #333;
      font-size: rem-calc(12);
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      @include cubic-transition;
      position: relative;
      a {
        color: inherit;
        display: block;
        padding: 30px 0;
      }
      &:hover , &.active {
        color: #09a3e0;
        font-weight: 700;
        text-decoration: none;
        @include cubic-transition;
        &:after {
          content: '';
          display: block;
          background: url("../images/page-list-after.png");
          width: 31px;
          height: 11px;
          position: absolute;
          z-index: 1;
          @include horizontal-center;
        }
      }
    }
  }
  .page-content {
    &.active {
      display: block;
    }
    .figure {
      margin: 0 0 30px;
      .image {
        width: 100%;
      }
    }
    .inner-box {
      padding: 0 30px;
    }
  }
}

.page-content {
  h6 {
    color: #3d3d3d;
    text-transform: uppercase;
    font-size: rem-calc(16);
    letter-spacing: 0.75px;
  }
  h4 {
    color: #09a3e0;
    font-weight: 400;
    font-size: rem-calc(24);
  }
}

@media (max-width:767px){
  #pages {
    .pages-list {
      box-shadow: none;
      li {
        width: 100%;
        display: block;
        margin: 0 0 6px;
        box-shadow: 0 0 25px 5px #d2d2d2;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
