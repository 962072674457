.btn-primary{
    min-width: 125px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem-calc(14);
    letter-spacing: 0.075em;
    padding-top: 10px;
    padding-bottom: 11px;
    background: #09a3e0;
    color: #fff;
        &:hover {
        color: #fff;
        background-color: #2fc4ff;
        border-color: #2fc4ff;
    }
}