.training-page {
    #pagehead {
        h1 {
            float: left;
            padding-top: 60px;
        }
        .query-section {
            float: right;
            min-width: 535px;
        }
        .inner{
            @include absolute-center;
            margin-top: -190px;
        }
        .container {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            max-width: 960px;
            margin: 0 auto;
            @include absolute-center;
        }
    }

}
@media screen and (max-width: 992px) {
    .training-page {
        #pagehead {
            .container {
                max-width: 720px;
            }
            h1 {
                float: none;
            }
            .query-section {
                float: none;
                max-width: 535px;
                margin: 0 auto;
                min-width: auto;
            }
        }
    }
}
@media screen and (max-width:767px){
    .training-page {
    #pagehead {

    }
        #wrapper {
        margin-top: 0px;
    }
}
}

.training{
    margin-bottom: 50px;
    hr{
        margin-bottom: 45px;
        margin-top: 45px;
    }
    img{
        border-radius:5px; 
    }
    .box1{
        margin-bottom: 0;
        padding-top: 30px;
        overflow: inherit;
        .inner-content{
            padding-right: 60px;
            padding-left: 30px;
        }
    }
    .image{
        margin: 0;
        img{
            object-fit: cover;
            object-position: top center;
            width: 100%;
            border-top-left-radius:10px;
            border-bottom-left-radius:10px;
        }
    }
    .inner-content{
        h3{
        letter-spacing: 0.075em;
        color: #000000;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 40px;
        }
        h5{
            color: #9c9c9c;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 40px;
        }
        p{
            color: #4f4f4f;
            font-size: rem-calc(14);
            margin-bottom:7px; 
        }
    }
}
@media screen and (max-width:992px){
    .training{
        .inner-content{
            h3{
                margin-bottom: 20px;
                font-size: rem-calc(21);
            }
            h5{
                margin-bottom: 20px;
                font-size: rem-calc(16);
            }
            p{
                font-size: rem-calc(14);
                margin-bottom:7px; 
            }
        }
    }


}
@media screen and (max-width:767px){
    .training{
        .box1{
            border-radius: 0;
            box-shadow: none;
        }
        img {
            border-radius: 0px;
        }
        .image {
            img {
                border-radius: 0px;
            }
        }
        .column{
            margin-bottom: 20px;
        }

        .inner-content , .box1 .inner-content{
            padding-right: 15px;
            padding-left: 15px;
        }
        hr{
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
}