.list2{
    margin: 0;
    padding: 0;
    list-style-type: none;
    >li{
        border-bottom: 1px solid rgba(80,109,123,.13);
        padding-bottom: 16px;
        padding-top: 10px;
      ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
          li{
            width: 33.33%;
            float: left;
            h4{
                color: $brand-primary;
                font-size: rem-calc(14);
                text-transform: capitalize;
                font-weight: 700;
                margin: 0;
                line-height: 1.8;
            }
            a , span{
                color: #4f4f4f;
                font-size: rem-calc(14);
            }
          }
      }   
    }
}
@media screen and (max-width:767px){
 .list2{
    >li{
      ul{
          li{
            width: 100%;
            float: none;
          }
      }   
    }
}   
}