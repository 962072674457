#footer{
  background-color: #232323;
  background-image: url("../images/footer.png");
  background-size: cover;
  background-position:center center; 
  padding-top: 43px;
  padding-bottom: 34px;
  nav{
    ul{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            text-transform: uppercase;
            font-size:rem-calc(12);
            line-height:rem-calc(18);
            
            font-weight: 500;
            margin-bottom: 17px;
             letter-spacing: 0.075em;
          a{
            color: #ffffff;
            &:hover{
              color: $brand-primary;
            }
          }
        }
    }
  }
  .column{
          border-right: 1px solid #424242;
    + .column{
          border-right: none;
    }
  }
}
@media screen and (max-width: 992px) {
  #footer{
      nav{
    ul{
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-width: 33.33%;
        -moz-column-width: 33.33%;
        column-width: 33.33%;
        li{
            margin-bottom: 18px;
        }
    }
  }
    .column{
      border-right: none;
          border-bottom: 1px solid #424242;
          padding-bottom: 55px;
    + .column{
          border-right: none; border-bottom:none;
    }
  }
  }
  
}
@media screen and (max-width: 767px) {
  #footer{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
          nav{
    ul{
      padding-top: 30px;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
        li{
            margin-bottom: 18px;
        }
    }
  }
  .col-md-5{
    img {
      display: block;
      margin: 0 auto 10px;
    }
  }
  }
}